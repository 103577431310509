/* eslint-disable no-useless-escape */
/*
 * @Author: gaojingran
 * @Date: 2021-03-20 09:48:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-29 10:06:26
 * @Description: utils
 */
import moment from 'moment'
import Cookies from 'js-cookie'
import store from '@/store'
// import that from '@/main.js'

// 清除登录信息
export function clearLoginInfo() {
  Cookies.remove('token')
  window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] = false
}

// 权限
export function hasPermission(key) {
  return store.state.app.permissions.indexOf(window.$g.permissions[key]) !== -1 || false
}

// 自动导入
export function requireAll(requireContext) {
  return requireContext.keys().map(requireContext)
}

// sleep
export function delay(time = 3000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

// uuid
export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8

    return v.toString(16)
  })
}

// 获取滚动条size
let cached
export function getScrollBarSize(fresh) {
  if (fresh || cached === undefined) {
    const inner = document.createElement('div')
    inner.style.width = '100%'
    inner.style.height = '200px'

    const outer = document.createElement('div')
    const outerStyle = outer.style

    outerStyle.position = 'absolute'
    outerStyle.top = 0
    outerStyle.left = 0
    outerStyle.pointerEvents = 'none'
    outerStyle.visibility = 'hidden'
    outerStyle.width = '200px'
    outerStyle.height = '150px'
    outerStyle.overflow = 'hidden'

    outer.appendChild(inner)

    document.body.appendChild(outer)

    const widthContained = inner.offsetWidth
    outer.style.overflow = 'scroll'
    let widthScroll = inner.offsetWidth

    if (widthContained === widthScroll) {
      widthScroll = outer.clientWidth
    }

    document.body.removeChild(outer)

    cached = widthContained - widthScroll
  }
  return cached
}

// 下载oss文件重命名
export const handleDownloadOssBlob = (url, name) => {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = () => {
    if (xhr.status === 200) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(xhr.response, name)
      } else {
        const link = document.createElement('a')
        const body = document.querySelector('body')
        link.href = window.URL.createObjectURL(xhr.response)
        link.download = name
        link.style.display = 'none'
        body.appendChild(link)
        link.click()
        body.removeChild(link)
        window.URL.revokeObjectURL(link.href)
      }
    }
  }
  xhr.send()
}

export const checkUrl = (sUrl) => {
  var sRegex =
    '^((https|http|ftp|rtsp|mms)?://)' +
    "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" + //ftp的user@
    '(([0-9]{1,3}.){3}[0-9]{1,3}' + // IP形式的URL- 199.194.52.184
    '|' + // 允许IP和DOMAIN（域名）
    "([0-9a-z_!~*'()-]+.)*" + // 域名- www.
    '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].' + // 二级域名
    '[a-z]{2,6})' + // first level domain- .com or .museum
    '(:[0-9]{1,4})?' + // 端口- :80
    '((/?)|' + // a slash isn't required if there is no file name
    "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$"
  var re = new RegExp(sRegex)
  if (re.test(sUrl)) {
    return true
  }
  return false
}
// export const checkUrl = (str) => {
//   var oRegUrl = new RegExp()
//   //aa.bb.com
//   oRegUrl.compile('^[A-Za-z]+://[A-Za-z0-9-_]+\\.[A-Za-z0-9-_%&?/.=]+$')
//   if (!oRegUrl.test(str)) {
//     return false
//   }
//   return true
// }

export const downloadUrl = (url, name) => {
  url = useAccelerateUrl(url)
  const ele = document.createElement('a')
  ele.style.display = 'none'
  ele.href = url
  if (name) {
    ele.download = name
  }
  console.log(ele)
  document.body.appendChild(ele)
  ele.click()
  document.body.removeChild(ele)
}

export const downloadBlob = (name, type, data) => {
  const blob = new Blob([data], { type })
  let url = window.URL.createObjectURL(blob)
  let eleLink = document.createElement('a')
  eleLink.href = url
  eleLink.download = name
  document.body.appendChild(eleLink)
  eleLink.click()
  window.URL.revokeObjectURL(url)
}

// 两时间差
export const diffDate = (startTime, endTime) => {
  const seconds = moment(endTime).diff(moment(startTime), 'seconds')
  if (seconds < 60) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds,
    }
  } else {
    const min_total = Math.floor(seconds / 60) // 分钟
    const sec = Math.floor(seconds % 60) // 余秒
    if (min_total < 60) {
      return {
        days: 0,
        hours: 0,
        minutes: min_total,
        seconds: sec,
      }
    } else {
      const hour_total = Math.floor(min_total / 60) // 小时数
      const min = Math.floor(min_total % 60) // 余分钟
      if (hour_total < 24) {
        return {
          days: 0,
          hours: hour_total,
          minutes: min,
          seconds: sec,
        }
      } else {
        const day_total = Math.floor(hour_total / 24) // 天
        const hour = Math.floor(hour_total % 24) // 剩余小时
        return {
          days: day_total,
          hours: hour,
          minutes: min,
          seconds: sec,
        }
      }
    }
  }
}

//编码
export const htmlEncode = (str) => {
  let s = ''
  if (str.length == 0) {
    return ''
  } else {
    s = str.replace(/&amp;/g, '&')
    s = s.replace(/&lt;/g, '<')
    s = s.replace(/&gt;/g, '>')
    s = s.replace(/&nbsp;/g, ' ')
    s = s.replace(/&#39;/g, "'")
    s = s.replace(/&quot;/g, '"')
    s = s.replace(/<br\/>/g, '\n')
    return s
  }
}

export const filterNull = (str) => {
  var arr = str.replace(
    /[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?]/g,
    ''
  )
  console.log(arr) //"0000"
  filterNull('0_0 (: /- :) 0-0')
}

// 下拉搜索筛选
export function langFilterOption(input, option) {
  return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

// 数组排序
export function orderBy(array, key, fun) {
  if (!array || !(array instanceof Array) || array.length === 0) {
    return array
  }
  if (!fun || !(fun instanceof Function)) {
    fun = function (m, n) {
      return m < n ? -1 : m > n ? 1 : 0
    }
  }
  if (!(array[0] instanceof Object)) {
    return array.sort(fun)
  }
  return array.sort(function (a, b) {
    const m = a[key]
    const n = b[key]
    if (m === undefined || m === null) {
      return 1
    }
    if (n === undefined || n === null) {
      return -1
    }
    return fun(m, n)
  })
}

// 数组排序（本地化规则）
export function localOrderBy(array, key, locales = 'zh') {
  return orderBy(array, key, function (m, n) {
    return m.toString().localeCompare(n, locales)
  })
}

// 正式环境两个 bucket 使用加速域名，将原来域名中的 oss-cn-hongkong 替换成 oss-accelerate
export function useAccelerateUrl(url) {
  if (!url) {
    return url
  }
  const needAccelerate = ['genplus-common-hk.oss-cn-hongkong', 'gentrans-prod.oss-cn-hongkong'].some((val) => {
    return url.indexOf(val) !== -1
  })
  if (!needAccelerate) {
    return url
  }
  return url.replace('oss-cn-hongkong', 'oss-accelerate')
}
