/*
 * @Author: gaojingran
 * @Date: 2021-03-20 13:14:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-17 17:09:05
 * @Description: 简体中文
 */

const t = {}

t.loading = '加载中...'

t.brand = {}
t.brand.lg = 'Neotrans Order'
t.brand.mini = 'Neotrans Order'

t.email = '邮箱'
t.add = '新增'
t.cancel = '取消'
t.confirm = '确定'
t.delete = '删除'
t.edit = '编辑'
t.search = '搜索'
t.update = '更新'
t.reset = '重置'
t.prev = '上一步'
t.next = '下一步'
t.submit = '提交'
t.sync = '同步'
t.evaluation = '评价'
t.export = '导出'
t.export_excel = '导出Excel'
t.refuse = '拒绝'
t.save = '保存'
t.action = '操作'
t.change = '修改'
t.preview = '预览'
t.tip = '提示'
t.copy = '复制'
t.upload_file_succeeded = '上传文件成功'
t.Quotation = 'Quotation'
t.null_link = '无效链接！'
t.xls = 'application/vnd.ms-excel application/x-excel'
t.xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

t.http = {}
t.http.title = '错误'
t.http.timeout = '服务器请求超时！'

t.pdf = {}
t.pdf.format = '尺寸'
t.pdf.landscape = '方向'
t.pdf.landscape_false = '纵向'
t.pdf.landscape_true = '横向'

t.tips = {}
t.tips.tip1 = '请通过终稿文件链接下载终稿！'
t.tips.tip2 = '供应商还未上传终稿！'

t.prompt = {}
t.prompt.title = '提示'
t.prompt.info = '确定进行 [{handle}] 操作?'
t.prompt.upload_illegal_format = '{name} 格式不支持上传!'
t.prompt.save_success = '保存成功!'
t.prompt.create_success = '新增成功!'
t.prompt.update_success = '修改成功!'
t.prompt.delete_success = '删除成功!'
t.prompt.edit_success = '编辑成功!'
t.prompt.set_success = '设置成功!'
t.prompt.update_success = '更新成功!'

t.dict = {}
t.dict.warning_tag_1 = '紧急'
t.dict.warning_tag_2 = '预警'
t.dict.warning_tag_3 = '正常'
// 订单状态
t.dict.order_status1 = '等待报价'
t.dict.order_status2 = '待确认报价'
t.dict.order_status3 = '已取消'
t.dict.order_status4 = '生产中'
t.dict.order_status5 = '待确认终稿'
t.dict.order_status6 = '终稿修改中'
t.dict.order_status8 = '已终止'
t.dict.order_status9 = '已确认终稿'
// 结算状态
t.dict.payment_status1 = '待下单方确认结算'
t.dict.payment_status2 = '待供应商确认结算'
t.dict.payment_status3 = '供应商驳回'
t.dict.payment_status4 = '供应商已确认'
t.dict.payment_status5 = '已开票'
// 订单进度
t.dict.orderProgress_status1 = '提交订单'
t.dict.orderProgress_status2 = '提交报价单'
t.dict.orderProgress_status3_1 = '拒绝报价，订单取消'
t.dict.orderProgress_status3_2 = '订单取消'
t.dict.orderProgress_status4 = '确认报价单'
t.dict.orderProgress_status5 = '提交终稿'
t.dict.orderProgress_status6 = '提交反馈修改'
t.dict.orderProgress_status7 = '提交修改稿'
t.dict.orderProgress_status8 = '终止订单'
t.dict.orderProgress_status9 = '已确认终稿'
t.dict.orderProgress_next_status1 = '等待供应商报价'
t.dict.orderProgress_next_status2 = '待确认报价'
t.dict.orderProgress_next_status4 = '生产中'
t.dict.orderProgress_next_status5 = '待确认终稿'
t.dict.orderProgress_next_status6 = '终稿修改中'
t.dict.orderProgress_next_status7 = '待确认终稿'
// 对接系统
t.dict.system_0 = '不对接生产系统'
t.dict.system_1 = '对接Neotrans1.0'
t.dict.system_2 = '对接鲸译(内部版)'
// 订单状态
t.dict.productionLineStatus_0 = '待激活'
t.dict.productionLineStatus_1 = '已激活'
// TM应用
t.dict.produceMtApply_0 = '不应用机翻结果到Target列'
t.dict.produceMtApply_1 = '应用机翻结果到Target列'
t.dict.customer_status_0 = '未确认'
t.dict.customer_status_1 = '已确认'

t.validate = {}
t.validate.required = '必填项不能为空'
t.validate.format = '{attr} 格式错误'
t.validate.int = '格式应为正整数'
t.validate.length_range = '长度应该是 {min} 到 {max} 个字符'
t.validate.min_size = '不少于 {size} 个字符'
t.validate.max_size = '少于等于 {size} 个字符'
t.validate.flost_size = '最大为 {size} 位小数的浮点数'
t.validate.upload_max_number = '你最多可上传 {number} 个文件'
t.validate.upload_max_size = '{filename} 的大小不能超过 {size}MB'
t.validate.upload_number_size =
  '可累计上传{number}个文件，单文件大小不超过{size}MB({accept})，zip包中仅含受支持的文件类型'
t.validate.upload_number_size_all_type = '可累计上传{number}个文件，单文件大小不超过{size}MB'
t.validate.earlier_time = '时间不能早于当前时间'
t.validate.decimal_0_1 = '请输入0到1的两位有效小数'
t.validate.only_num_letter = '只能输入数字和英文字母'
t.validate.regular_str_less_size = '只能输入中文，英文，数字等常规字符并且少于等于 {size} 个'
t.validate.only_zh_en_num = '只能输入中文、英文和数字'
t.validate.only_num = '只能输入数字'
t.validate.supplier_info = '该供应商没有“{s}到{t}”{documentType}的{serviceType}单价，可能会影响到后续的报价'
t.validate.no_gt_email = '不可使用 pactera.com 和 gientech.com 的邮箱'
t.validate.upload_num_file = '请上传字数文件'

t.placeholder = {}
t.placeholder.select = '请选择 {attr}'
t.placeholder.input = '请输入 {attr}'

// 日期格式化
t.dateFormat = {}
t.dateFormat.a = 'YYYY/MM/DD HH:mm'
t.dateFormat.b = 'YYYY/MM/DD'
t.dateFormat.c = 'YYYY年MM月DD日 HH:mm'
t.dateFormat.d = 'YYYY年MM月'
t.dateFormat.e = 'YYYY年MM月DD日'
t.dateFormat.f = 'YYYY-MM-DD HH:mm'
t.dateFormat.g = 'YYYYMMDD'

// 文件拖拽上传
t.uploadDragger = {}
t.uploadDragger.title = '文件列表'
t.uploadDragger.table_filename = '文件名'
t.uploadDragger.table_percent = '进度'
t.uploadDragger.table_action = '操作'
t.uploadDragger.upload_translation_file = '点击或者拖拽待翻文件到这里上传'
t.uploadDragger.upload_attachment_file = '点击或者拖拽附件类的订单备注到这里上传'

// 文件上传按钮
t.uploadButton = {}
t.uploadButton.title = '上传文件'

// module layout
t.layout = {}
t.layout.draft_box = '草稿箱'
t.layout.update_password = '修改密码'
t.layout.update_userInfo = '修改资料'
t.layout.logout = '退出登录'
t.layout.new_order = '立即下单'

// 登录
t.login = {}
t.login.title = 'WELCOME TO GientCloud!'
t.login.subTitle = 'One stop solution, 24/7 steward makes you manage order so easy!'
t.login.chrome = '为了避免兼容性问题，请使用Chrome浏览器'
t.login.email = '邮箱'
t.login.password = '密码'
t.login.verify = '验证码'
t.login.noverify = '验证码不能为空'
t.login.form_account = '请输入您的注册邮箱'
t.login.form_account_holder = '请输入您的登录邮箱'
t.login.form_password = '请输入您的密码'
t.login.form_password_holder = '请输入密码'
t.login.form_tenant_code_holder = '请输入识别码'
t.login.form_client_holder = '请选择不同客户方实体'
t.login.form_supplier_holder = '请选择不同供应商实体'
t.login.form_verify = '请输入验证码'
t.login.form_verify_holder = '请输入验证码'
t.login.submit = '立即登录'
t.login.forget_password = '忘记密码'
t.login.new_password = '新密码'
t.login.new_password_holder = '请输入您的新密码'
t.login.confirm_password = '确认密码'
t.login.confirm_password_holder = '请再次确认您的新密码'
t.login.check_confirm = '二次输入密码不一致'
t.login.mail_verify_holder = '请输入邮箱验证码'
t.login.send_verify_code = '发送验证码'
t.login.send_verify_code_success = '发送验证码成功'
t.login.verify_code_not_send_yet = '尚未发送验证码'
t.login.reset_password_success = '重置密码成功'

// 404
t.notFound = {}
t.notFound.desc = '抱歉！您访问的页面"失联"啦...'
t.notFound.back = '上一页'
t.notFound.home = '首页'

//修改密码
t.modifyPassword = {}
t.modifyPassword.title = '修改密码'
t.modifyPassword.old_password = '旧密码'
t.modifyPassword.new_password = '新密码'
t.modifyPassword.confirm_password = '确认密码'
t.modifyPassword.confirm_modify = '确认修改'
t.modifyPassword.confirm_old_password = '请输入您的旧密码'
t.modifyPassword.confirm_new_password = '请输入您的新密码'
t.modifyPassword.confirm_new_password2 = '请再次输入您的新密码'
t.modifyPassword.check_confirm = '两次输入密码不一致'
t.modifyPassword.modify_success = '修改成功! '
t.modifyPassword.pw_modify_success = '密码修改成功! '

//修改资料
t.modifyUserInfo = {}
t.modifyUserInfo.title = '修改资料'
t.modifyUserInfo.username = '用户名'
t.modifyUserInfo.role = '角色'
t.modifyUserInfo.sex = '性别'
t.modifyUserInfo.male = '男'
t.modifyUserInfo.female = '女'
t.modifyUserInfo.phone = '手机'
t.modifyUserInfo.department = '部门'
t.modifyUserInfo.timezone = '时区'
t.modifyUserInfo.entity = '公司实体'
t.modifyUserInfo.line = '产品线'
t.modifyUserInfo.confirm_modify = '确认修改'
t.modifyUserInfo.modify_success = '修改成功'
t.modifyUserInfo.user_modify_success = '资料修改成功! '

// 创建订单
t.newOrder = {}
t.newOrder.title = '创建一个新的订单'
t.newOrder.order_name = '订单名称'
t.newOrder.order_name_holder = '请输入订单名称'
t.newOrder.order_name_holder_customized = '产品线批次（例AOP batch3）'
t.newOrder.product_line = '产品线'
t.newOrder.task_type = '任务类型'
t.newOrder.task_type_holder = '请选择任务类型'
t.newOrder.supplier = '供应商'
t.newOrder.supplier_holder = '请选择供应商'
t.newOrder.product_line_holder = '请选择产品线'
t.newOrder.doc_type = '文档类型'
t.newOrder.doc_type_holder = '请选择文档类型'
t.newOrder.delivery_date = '订单交期'
t.newOrder.service_type = '服务类型'
t.newOrder.service_type_holder = '请选择服务类型'
t.newOrder.fine = '需要精确排版'
t.newOrder.fine_tip = '精确排版指终稿文件经过了人工精确排版，需额外收费'
t.newOrder.target = '目标语言'
t.newOrder.target_holder = '请选择目标语言'
t.newOrder.source = '源语言'
t.newOrder.source_holder = '请选择源语言'
t.newOrder.order_note = '订单备注'
t.newOrder.order_fileName = '翻译源文件：'
t.newOrder.order_note_holder = '请输入订单备注'
t.newOrder.save_draft = '保存草稿箱'
t.newOrder.set_notice_mail = '设置通知邮箱'
t.newOrder.copy_mail = '抄送邮箱'
t.newOrder.get_quote = '获取报价'
t.newOrder.auto_quote = '自动报价'
t.newOrder.get_quote_desc = '点击后，订单将无法修改，等待供应商报价，确认继续吗? '
t.newOrder.note_label = '注'
t.newOrder.note_desc = '文件翻译目前只提供人工报价'
t.newOrder.error_lang_tips = '{language} 不在语言列表中'
t.newOrder.prompt_save_draft = '订单是否保存至草稿箱？'
t.newOrder.project_manage_cost_head = '含有'
t.newOrder.project_manage_cost_tail = '% 项目管理费'
t.newOrder.project_manage_text = '项目管理'
t.newOrder.wait_trans_file_link = '待翻文件链接'
t.newOrder.wait_trans_file_link_holder = '请输入OneDrive的文件链接'
t.newOrder.order_need_notice = '下单时需通知'
t.newOrder.order_need_notice2 = '供应商上传终稿时需通知'
t.newOrder.size_max_20 = '上传文档多于20个，请重新选择。'

t.newOrder.productLine_tip = '注：请谨慎选择产品线，确保正确，因其会影响供应商推荐准确度。'
t.newOrder.supplier_tip = '注：请选择推荐的供应商，零散需求除外。'
t.newOrder.copy_email_to = '邮件抄送至'
t.newOrder.submit_order = '提交订单'
// 订单详情
t.orderInfo = {}
t.orderInfo.title = '订单信息'
t.orderInfo.waiting_quotation = '当前正在等待供应商报价中'
t.orderInfo.autoConfirmDate = '终稿将在{days}天{hours}小时{minutes}分{seconds}秒后确认'
t.orderInfo.no_rate_info = '该订单暂无评价！'
t.orderInfo.no_word_count = '生产系统还未生成生产字数，请检查过后再试！'
t.orderInfo.without_word_report_message = '字数表不允许为空！'
t.orderInfo.with_compose = '（含精排）'
t.orderInfo.order_delivery = '订单交期'
t.orderInfo.order_amount = '订单金额'
t.orderInfo.quote_amount = '报价金额'
t.orderInfo.contact = '联系方式'
t.orderInfo.manager = '交付经理'
t.orderInfo.managerVendor = '下单经理'
t.orderInfo.progress = '订单进度'
t.orderInfo.operator = '操作人'
t.orderInfo.current = '当前进度'
t.orderInfo.translate_progress = '翻译进度'
t.orderInfo.table_target = '目标语言'
t.orderInfo.table_word_count = '字数 (WWC)'
t.orderInfo.table_step = '当前阶段'
t.orderInfo.table_step_progress = '阶段进度'
t.orderInfo.source = '源语言'
t.orderInfo.target = '目标语言'
t.orderInfo.order_name = '订单名称'
t.orderInfo.service_type = '服务类型'
t.orderInfo.doc_type = '文档类型'
t.orderInfo.product_line = '产品线'
t.orderInfo.lang_pair = '语言对'
t.orderInfo.industry = '行业领域'
t.orderInfo.neo_code = 'Neo-code'
t.orderInfo.order_declare = '订单说明'
t.orderInfo.translate_file = '翻译源文件'
t.orderInfo.provider = '供应商实体'
t.orderInfo.suggestions = '修改意见'
t.orderInfo.new_delivery_time = '修改稿交付日期'
t.orderInfo.see_history_records = '查看历史反馈记录'
t.orderInfo.see_finalDraft_records = '查看历史终稿记录'
t.orderInfo.btn_online_consult = '问题跟踪'
t.orderInfo.btn_check_quote = '查看报价单'
t.orderInfo.btn_create_quote = '创建报价'
t.orderInfo.btn_submit_quote = '提交报价'
t.orderInfo.btn_cancel_order = '取消订单'
t.orderInfo.btn_modify_order = '修改订单'
t.orderInfo.btn_stop_order = '终止订单'
t.orderInfo.btn_download_finish = '下载终稿'
t.orderInfo.btn_rate = '评价'
t.orderInfo.btn_check_rate = '查看评价'
t.orderInfo.btn_submit_finish = '确认终稿'
t.orderInfo.btn_feedback_edit = '反馈修改'
t.orderInfo.btn_upload_finish = '上传终稿'
t.orderInfo.cancel_order_title = '取消订单'
t.orderInfo.cancel_order_info = '点击后，订单被召回，流程结束，是否继续？'
t.orderInfo.stop_order_title = '终止订单'
t.orderInfo.stop_order_info =
  '终止后，任务将被紧急停止，订单进入结算阶段，且不可恢复生产；请与交付经理协商发生的工作成本，确定结算费用。确定要终止订单吗？'
t.orderInfo.stop_order_cancel = '我再想想'
t.orderInfo.stop_order_ok = '确认终止'
t.orderInfo.success_confirm = '成功确认终稿'
t.orderInfo.success_confirm_desc = '本次线上订单新增语料 <span class="error">{count}</span> 条。'
t.orderInfo.success_confirm_rate_title = '请您为本次订单评价'
t.orderInfo.rate_label_delivery = '按时交付'
t.orderInfo.rate_label_quality = '质量'
t.orderInfo.rate_label_response = '响应度'
t.orderInfo.rate_label_manner = '态度'
t.orderInfo.rate_label_satisfaction = '满意度'
t.orderInfo.rate_label_score = '评分'
t.orderInfo.success_confirm_ava_score_info = '感谢您的评价，本次订单评分为'
t.orderInfo.success_confirm_ava_score_info_unit = '分'
t.orderInfo.rate_holder = '请填写更多关于本次订单的评价'
t.orderInfo.find_paper_holder = '请填写本次终稿说明'
t.orderInfo.feedback_holder = '请输入你对终稿的修改意见'
t.orderInfo.feedback_label = '注'
// t.orderInfo.feedback_tips = '您仅有一次提交修改的机会'
t.orderInfo.feedback_tips = '最新的反馈修改附件内容及交付日期将覆盖之前的版本'
t.orderInfo.quote_radio_sync = '同步生产系统字数报告'
t.orderInfo.quote_radio_log = '上传线下日志'
t.orderInfo.download_template = '下载模板'
t.orderInfo.table_lang = '语言'
t.orderInfo.table_lang_double = '语言对'
t.orderInfo.table_matching = '匹配区间'
t.orderInfo.table_wwc = 'WWC'
t.orderInfo.finish_translate_time = '预计完成翻译所需时间'
t.orderInfo.finish_translate_unit = '个工作日'
t.orderInfo.quote = '报价单'
t.orderInfo.quote_info = '报价单详情'
t.orderInfo.quote_title = '订单'
t.orderInfo.quote_customer = '下单方实体'
t.orderInfo.quote_provider = '供应商实体'
t.orderInfo.quote_address = '地址'
t.orderInfo.quote_contact = '联系人'
t.orderInfo.table_source = '源语言'
t.orderInfo.table_serviceType = '服务类型'
t.orderInfo.table_price = '单价'
t.orderInfo.table_number = '数量'
t.orderInfo.table_totalNumber = '总数目'
t.orderInfo.table_totalPrice = '总价'
t.orderInfo.table_footer_label = '注'
t.orderInfo.table_footer_desc = '翻译的字数，指权重字数，详情见底部“WWC字数明细”'
t.orderInfo.table_footer_desc2 = '翻译的字数，指权重字数'
t.orderInfo.table_footer_priceTotal = '报价总计'
t.orderInfo.table_order_num = '订单号'
t.orderInfo.table_order_name = '订单名称'
t.orderInfo.table_order_price_date = '报价日期'
t.orderInfo.table_order_status = '状态'
t.orderInfo.table_order_total_price = '订单总价'
t.orderInfo.table_order_footer_count = '共 <span class="primary">{number}</span> 单'
t.orderInfo.quote_wwc_detail = 'WWC字数明细'
t.orderInfo.btn_quote_approve = '批准报价'
t.orderInfo.btn_quote_reject = '退回重新报价'
t.orderInfo.btn_quote_newOption = '新增报价选项'
t.orderInfo.btn_quote_changeOrderType = '更改订单类型'
t.orderInfo.quote_no_price = '尚未有单价，请联系下单方管理员添加价格'
t.orderInfo.quote_init_price_holder = '请选择单价'
t.orderInfo.quote_confirm_title = '拒绝报价'
t.orderInfo.quote_confirm_info = '本订单将被取消，流程结束，是否继续？'
t.orderInfo.quote_confirm_okText = '确认终止'
t.orderInfo.quote_confirm_cancelText = '我再想想'
t.orderInfo.quote_reject_title = '退回重新报价'
t.orderInfo.quote_reject_info = '本订单将会退回供应商重新报价，是否继续？'
t.orderInfo.quote_reject_okText = '确认退回'
t.orderInfo.quote_reject_cancelText = '我再想想'
t.orderInfo.quote_approve_confirm_date = '您可以最后一遍确认交付日期'
t.orderInfo.quote_approve_note_label = '注'
t.orderInfo.quote_approve_note = '交付经理预计完成翻译所需{count}个工作日'
t.orderInfo.quote_approve_warning = '订单将进入翻译生产阶段，是否继续？'
t.orderInfo.quote_approve_due_date = '终稿交期'
t.orderInfo.quote_option_type = '类型'
t.orderInfo.quote_option_price = '单价'
t.orderInfo.quote_option_number = '数量'
t.orderInfo.quote_option_total = '总价'
t.orderInfo.download_file = '下载附件'
t.orderInfo.upload_num_file = '字数文件'
t.orderInfo.num_read = '字数预览'
t.orderInfo.MB_less_5 = '上传的文件不能大于5MB！'
t.orderInfo.jinyiSteps = '第{index}步'
t.orderInfo.upload_translation_records = '上传翻译记录'
t.orderInfo.final_document_link = '终稿文件链接'
t.orderInfo.final_document_no_empty = '终稿文件不能为空'
t.orderInfo.final_document = '终稿文件'
t.orderInfo.final_document_show = '终稿说明'
t.orderInfo.please_enter_file_link_for_onedrive = '请输入OneDrive的文件链接'
t.orderInfo.select_order_no_feedback = '所选订单皆没有反馈内容！'
t.orderInfo.upload_again = '重新上传'
t.orderInfo.confirm_final_draft = '您是否已确认验收该终稿？'
t.orderInfo.step_irrevocable = '此步骤不可撤回！'
t.orderInfo.confirm = '确认'

t.orderInfo.Translation = '翻译'
t.orderInfo.Edit = '校对'
t.orderInfo.Proofreading = '可读性检查'
t.orderInfo.QualityAssurance = '抽检'
t.orderInfo.FeedbackImplementation = '反馈'
t.orderInfo.remark = '备注'
t.orderInfo.inputRemark = '请输入 备注'
t.orderInfo.remarkTip = '备注不超过20个字'
t.orderInfo.file = '待翻文件'
t.orderInfo.supplier = '供应商'
t.orderInfo.order_note = '订单备注'
t.orderInfo.line = '产品线'
t.orderInfo.order_attachment = '订单附件'
t.orderInfo.task_type = '任务类型'

// 首页
t.home = {}
t.home.all = '全部'
t.home.more = '查看更多'
t.home.one_month = '近一个月'
t.home.one_month_short = '近一月'
t.home.third_month = '近三个月'
t.home.third_month_short = '近三月'
t.home.six_month = '近半年'
t.home.twelve_month = '近一年'
t.home.one_year = '近一年'
t.home.cumulative_order = 'Order Number'
t.home.cumulative_order_subtitle = '累计下单'
t.home.cumulative_order_unit = '单'
t.home.cumulative_corpora = 'Corpora Amount'
t.home.cumulative_corpora_subtitle = '累计语料'
t.home.cumulative_corpora_unit = '条'
t.home.cost_saving = 'Saved Cost'
t.home.cost_saving_subtitle = '节约成本'
t.home.cost_saving_unit = '元'
t.home.shorten_time = 'Turnaround Shorten'
t.home.shorten_time_subtitle = '缩短周期'
t.home.order_volume = 'Order Volume'
t.home.order_volume_subtitle = '订单金额'
t.home.translated_wordcount = 'Translated Wordcount'
t.home.translated_wordcount_subtitle = '翻译字数'
t.home.btn_order = '立即下单'
t.home.btn_order_holder = 'Order Now'
t.home.btn_quote = '审核报价'
t.home.btn_quote_holder = 'Review Quotation'
t.home.btn_accept = '验收终稿'
t.home.btn_accept_holder = 'Confirm Translation'
t.home.btn_account = '确认结算'
t.home.btn_account_holder = 'Confirm Settlement'
t.home.btn_create_quotation = '创建报价'
t.home.btn_create_quotation_holder = 'Create Quotation'
t.home.settlement_holder = 'Confirm Settlement'
t.home.todo = '待办事项'
t.home.table_index = '序号'
t.home.table_order = '待处理订单'
t.home.table_status = '状态'
t.home.table_update_time = '更新时间'
t.home.order_name_rule1 = '待您上传终稿'
t.home.order_name_rule2 = '待您确认报价单'
t.home.order_name_rule3 = '个订单终稿验收通过，待您确认结算金额'
t.home.order_name_rule4 = '个订单结算金额被供应商驳回，待您修改'
t.home.order_name_rule5 = '待您确认终稿'
t.home.order_name_rule6 = '待您创建报价'
t.home.order_name_rule7 = '客户已反馈修改，待您重新提交终稿'
t.home.order_name_rule8 = '个订单终稿验收通过，待您确认结算金额'
t.home.order_name_rule9 = '订单待您回复问题'
t.home.end = '截止'
t.home.place_order = '下单'
t.home.updated_on = '更新于'
t.home.confirmInfo = '请确认以下信息是否正确'
t.home.remakes = '信息一经确认后，需通过平台管理员才能修改'

// 统计报表
t.report = {}
t.report.cumulative_order = 'Cumulative Order'
t.report.cumulative_order_subtitle = '累计下单'
t.report.cumulative_order_unit = '单'
t.report.cumulative_corpora = 'Cumulative Corpora'
t.report.cumulative_corpora_subtitle = '累计语料'
t.report.cumulative_corpora_unit = '条'
t.report.order_volume = 'Order Volume'
t.report.order_volume_subtitle = '订单金额'
t.report.translated_wordcount = 'Translated Wordcount'
t.report.translated_wordcount_subtitle = '翻译字数'

//企业配置
t.coConfig = {}
t.coConfig.base_info = '基本信息'
t.coConfig.productionline_config = '产品线配置'
t.coConfig.supplier_management = '供应商管理'
t.coConfig.manager = '下单经理'
t.coConfig.coTax = '企业税号'
t.coConfig.company_name = '企业实体名'
t.coConfig.company_address = '企业地址'
t.coConfig.currency = '币种'
t.coConfig.field = '行业领域&产品线'
t.coConfig.addNewField = '新增产品线'
t.coConfig.addProductLine = '新建产品线'
t.coConfig.fieldTip = '下单时需选择产品线，有助于将不同翻译需求进行分类管理'
t.coConfig.serviceType = '服务类型'
t.coConfig.addNewServiceType = '新增服务类型'
t.coConfig.documentType = '文档类型'
t.coConfig.addNewDocumentType = '新增文档类型'

t.coConfig.buy_visible = '下单时可见'
t.coConfig.buy_invisible = '下单时不可见'

// 客户管理
t.customer = {}
t.customer.title = '客户管理'
t.customer.search_holder = '输入客户税号或名称搜索'
t.customer.add_company = '添加客户'
t.customer.id = '客户编号'
t.customer.join_time = '加入时间'
t.customer.name = '实体名'
t.customer.Pline_name = '产品线'
t.customer.spm = '高级交付经理'
t.customer.action = '操作'
t.customer.check_details = '查看详情'
t.customer.taxIdNumber = '客户税号'
t.customer.status = '状态'
t.customer.topManager = '企业管理员'
t.customer.update = '编辑'
t.customer.save_company = '新增客户'
t.customer.update_company = '修改客户'
t.customer.currency = '请选择币种'

// 添加客户
t.newCompany = {}
t.newCompany.title = '添加客户'
t.newCompany.select_company = '请输入客户项目编号 (PID)'
t.newCompany.company_code = '企业代码'
t.newCompany.spm = '高级交付经理'
t.newCompany.company_name = '企业实体名'
t.newCompany.supplier_name = '供应商实体名'
t.newCompany.company_address = '企业地址'
t.newCompany.supplier_address = '供应商地址'
t.newCompany.currency = '币种'
t.newCompany.success_title = '创建成功'
t.newCompany.sub_title =
  '客户账号和产品线 {name}（已激活）创建成功！请前往客户详情页，完成客户价格、干系人信息后，开启下单流程'
t.newCompany.back_to = '返回客户管理'
t.newCompany.go_agency_details = '进入详情'
t.newCompany.cus_tax = '客户方税号'
t.newCompany.cus_tax_holder = '请输入客户方税号'
t.newCompany.cus_entityname = '客户方实体名'
t.newCompany.cus_entityname_holder = '请输入客户方实体名'
t.newCompany.cus_address = '客户方地址'
t.newCompany.cus_address_holder = '请输入客户方地址'
t.newCompany.cus_currency = '客户方币种'
t.newCompany.cus_admin = '客户方管理员'
t.newCompany.cus_admin_name_holder = '请输入姓名'
t.newCompany.cus_client_name = '请输入该客户在翻译系统上的ClientName'
t.newCompany.cus_admin_email_holder = '请输入邮箱'
t.newCompany.cus_comMapping_name = '请输入该客户在鲸译系统上的ClientName'
t.newCompany.cus_tax_existed = '客户方税号已存在'
t.newCompany.cus_entityname_existed = '客户方实体名已存在'
t.newCompany.cus_admin_email_existed = '客户方管理员邮箱已存在'

// 添加产品线（Pline)
t.newPline = {}
t.newPline.add_Pline = '新增产品线'
t.newPline.pid_exsit_prompt = '该pid不存在'
t.newPline.no_sync_prompt = '请先同步数据'
t.newPline.prompt_new_Pline = '请至少为客户创建一条产品线'
t.newPline.prompt_dock_Neo = '请为该产品线进行生产配置(对接Neotrans)'
t.newPline.basic_setup = '基本设置'
t.newPline.Neo_setup = '生产配置'
t.newPline.title_basic_info = '基本配置'
t.newPline.title_product_dock = '生产对接'
t.newPline.title_offer_config = '报价配置'
t.newPline.title_basic_config = '基本配置'
t.newPline.title_corpus_config = '语料配置'
t.newPline.title_person_config = '人员配置'
t.newPline.Pline_name = '产品线名'
t.newPline.Pline_name_holder = '请输入产品线名称'
t.newPline.industry_field = '行业领域'
t.newPline.industry_field_holder = '请选择行业领域'
t.newPline.dock_Neo = '对接Neotrans1.0'
t.newPline.dock_new_Neo = '对接鲸译(内部版)'
t.newPline.default_offer_mode = '默认报价方式'
t.newPline.artificial_offer = '人工报价'
t.newPline.offer_mode = '报价方式'
t.newPline.client_project = '客户项目编号(PID)'
t.newPline.client_project_holder = '请选择项目'
t.newPline.job_mode = 'Job Mode'
t.newPline.job_mode_holder = '请选择job mode'
t.newPline.job_type = 'Job Type'
t.newPline.job_type_holder = '请选择job type'
t.newPline.ratio = 'Ratio'
t.newPline.ratio_holder = '请选择ratio'
t.newPline.engine = '机翻引擎'
t.newPline.engine_holder = '请选择机翻引擎'
t.newPline.apply_result = '应用机翻结果到Target列'
t.newPline.delivery_manager = '交付经理'
t.newPline.delivery_manager_holder = '请选择交付经理'
t.newPline.product_manager = '项目执行'
t.newPline.product_manager_holder = '请选择项目执行'
t.newPline.sync_error_prompt = '高级交付经理与当前用户不一致'
t.newPline.doc_type = '文档类型'
t.newPline.tm_and_term = 'TM&Term组'
t.newPline.tm_and_term_holder = '本产品线UI语料组(系统默认新建)'
t.newPline.tm_reverse = 'TM反向应用？'
t.newPline.match_range = '匹配区间'
t.newPline.pm_or_ice = 'PC/ICE'
t.newPline.one_hundred = '100%'
t.newPline.repetition = 'Repetition'
t.newPline.ninety_five = '95%-99%'
t.newPline.eighty_five = '85%-94%'
t.newPline.seventy_five = '75%-84%'
t.newPline.mt = 'MT'
t.newPline.zero = '0-74%'
t.newPline.success_btn = '确认'
t.newPline.success_title = '创建成功'
t.newPline.success_content = '已创建成功!'
t.newPline.Neotrans_product_config = '生产配置'
// t.newPline.Neotrans_product_config = 'Neotrans生产配置'
t.newPline.tooltop =
  '为了使客户下单体验良好，当客户下单时选择的源语言和文档类型无法与现有Program相匹配时，系统将默认新建一个Program,请您选择这个默认Program绑定的ClientProject'
t.newPline.source_content = '{tooltop}的source和content type一致不可被绑定在同一条产品线上'
t.newPline.program = 'Program'
t.newPline.btn_check = '查看'
t.newPline.new_program_client_project = '默认绑定Client Project'
t.newPline.table_program = 'Program'
t.newPline.table_source = 'Source'
t.newPline.table_content_type = 'Content Type'
t.newPline.attention = '注：'
t.newPline.attention_1 = 'Source和Content Type相同的Program不能同时被绑定在同一个产品线中'
t.newPline.attention_2 =
  '2.如客户下单时选择的Source和Content Type与已有的Program不配，生产系统将默认新建一个Program并与该产品线绑定'
t.newPline.attention_3 = '3.新建的Program将以邮件的形式告知该产品线的交付经理'

// 客户详情-基本信息
t.companyInfo = {}
t.companyInfo.base_info = '基本信息'
t.companyInfo.price_list = '价格列表'
t.companyInfo.manager = '下单经理'
t.companyInfo.custom_set = '自定义配置'
t.companyInfo.btn_new_productionline = '新增产品线'
t.companyInfo.btn_new_price = '新增价格'
t.companyInfo.title_edit_price = '编辑价格'
t.companyInfo.btn_new_manager = '新增下单经理'
t.companyInfo.btn_bulk_import = '批量导入'
t.companyInfo.btn_download = '下载模板'
t.companyInfo.action = '操作'
t.companyInfo.action_details = '详情'
t.companyInfo.action_active = '激活'
t.companyInfo.action_edit = '编辑'
t.companyInfo.action_delete = '删除'
t.companyInfo.action_logout = '注销'
t.companyInfo.productionline_config = '产品线配置'
t.companyInfo.title_new_price = '新增价格'
t.companyInfo.update_spm_success = '更新成功'
t.companyInfo.artificial_offer = '人工报价'
t.companyInfo.automatic_offer = '自动报价'
t.companyInfo.active_success = '激活成功'
t.companyInfo.new_manager_title = '新增下单经理'
t.companyInfo.edit_manager_title = '编辑下单经理'

// 客户详情-产品线配置
t.companyInfo.Pline_name = '产品线名'
t.companyInfo.Pline_statu = '产品线状态'
t.companyInfo.Pline_manager = '交付经理'
t.companyInfo.Pline_dock = '生产对接'
t.companyInfo.Pline_mode = '报价方式'
t.companyInfo.Pline_updatetime = '更新时间'
t.companyInfo.active = '激活'
t.companyInfo.wait_active = '待激活'

// 客户详情-价格列表
t.priceList = {}
t.priceList.id = 'ID'
t.priceList.service_type = '服务类型'
t.priceList.service_type_holder = '请选择服务类型'
t.priceList.industry_field = '行业领域'
t.priceList.industry = '行业'
t.priceList.field = '行业领域'
t.priceList.doc_type = '文档类型'
t.priceList.doc_type_holder = '请选择文档类型'
t.priceList.source_language = '源语言'
t.priceList.source_language_holder = '请选择源语言'
t.priceList.target_language = '目标语言'
t.priceList.target_language_holder = '请选择目标语言'
t.priceList.price = '价格'
t.priceList.price_holder = '请输入价格'
t.priceList.unit = '单位'
t.priceList.unit_holder = '请选择单位'
t.priceList.currency = '币种'
t.priceList.action = '操作'
t.priceList.create_success = '创建成功'
t.priceList.delete_success = '删除成功'
// 新增价格
t.newPrice = {}
t.newPrice.delete_confirm_title = '是否确认删除'
t.newPrice.delete_yes = '是'
t.newPrice.delete_no = '否'

// 客户详情-下单经理
t.managerInfo = {}
t.managerInfo.name = '姓名'
t.managerInfo.email = '邮箱'
t.managerInfo.phone_number = '手机'
t.managerInfo.department = '部门'
t.managerInfo.statu = '状态'
t.managerInfo.senior_manager = '权限'
t.managerInfo.join_time = '加入时间'
t.managerInfo.action = '操作'
t.managerInfo.name_holder = '请输入姓名'
t.managerInfo.email_holder = '请输入邮箱'
t.managerInfo.phone_number_holder = '请输入手机'
t.managerInfo.department_holder = '请输入部门'
t.managerInfo.gender = '性别'
t.managerInfo.permission = '权限'
t.managerInfo.male = '男'
t.managerInfo.female = '女'
t.managerInfo.tip_label = '注'
t.managerInfo.tip_text = '邮箱请谨慎填写，提交后不可修改'
t.managerInfo.success_title = '创建成功'
t.managerInfo.success_content = '账户已创建成功'
t.managerInfo.set_jon = '设置在职'
t.managerInfo.set_departure = '设置离职'
t.managerInfo.job = '在职'
t.managerInfo.departure = '离职'
t.managerInfo.admin = '企业管理员'
t.managerInfo.manager = '下单经理'
t.managerInfo.staff = '下单员'
t.managerInfo.order_admin = '订单管理员'
t.managerInfo.edit_title = '确认'
t.managerInfo.edit_success = '下单经理编辑成功'
t.managerInfo.edit_price_success = '编辑成功'
t.managerInfo.create_error = '创建失败'
t.managerInfo.create_error_info = '该邮箱已被注册'
t.managerInfo.set_success_content = '设置成功'
t.managerInfo.import_success_message = '导入成功'

// 产品线详情
t.PlineDetails = {}
t.PlineDetails.basic_setup = '基本设置'
t.PlineDetails.Neo_setup = '生产配置'
t.PlineDetails.title_basic_config = '基本配置'
t.PlineDetails.title_offer_config = '报价配置'
t.PlineDetails.title_basic_config = '基本配置'
t.PlineDetails.title_corpus_config = '语料配置'
t.PlineDetails.title_person_config = '人员配置'
t.PlineDetails.ccEmails = '抄送群组'
t.PlineDetails.Pline_name = '产品线名'
t.PlineDetails.industry_field = '行业领域'
t.PlineDetails.product_dock = '生产对接'
t.PlineDetails.industry_field_holder = '请选择行业领域'
t.PlineDetails.dock_Neo = '对接Neotrans'
t.PlineDetails.default_offer_mode = '默认报价方式'
t.PlineDetails.offer_mode = '人工报价'
t.PlineDetails.client_project = 'Client Project'
t.PlineDetails.job_mode = 'Job Mode'
t.PlineDetails.job_type = 'Job Type'
t.PlineDetails.ratio = 'Ratio'
t.PlineDetails.engine = '机翻引擎'
t.PlineDetails.apply_result = '应用机翻结果到Target列'
t.PlineDetails.delivery_manager = '交付经理'
t.PlineDetails.product_manager = '生产系统项目经理'
t.PlineDetails.doc_type = '文档类型'
t.PlineDetails.tm_and_term = 'TM&Term组'
t.PlineDetails.tm_and_term_holder = '本产品线UI语料组(系统默认新建)'
t.PlineDetails.tm_reverse = 'TM反向应用？'
t.PlineDetails.match_range = '匹配区间'
t.PlineDetails.discount_ratio = '折扣率'
t.PlineDetails.pm_or_ice = 'PC/ICE'
t.PlineDetails.one_hundred_percent = '100%'
t.PlineDetails.repetition = 'Repetition'
t.PlineDetails.ninety_five = '95%-99%'
t.PlineDetails.eighty_five = '85%-94%'
t.PlineDetails.seventy_five = '75%-84%'
t.PlineDetails.mt = 'MT'
t.PlineDetails.zero = '0-74%'
t.PlineDetails.program = 'Program'
t.PlineDetails.new_program_client_project = '默认绑定Client Project'

//草稿箱
t.draftBox = {}
t.draftBox.order_num = '订单号'
t.draftBox.order_name = '订单名称'
t.draftBox.order_save_time = '保存时间'
t.draftBox.order_action = '操作'
t.draftBox.order_action_edit = '编辑'

//待办事项
t.schedule = {}
t.schedule.all = '全部'
t.schedule.urgent = '紧急'
t.schedule.warning = '预警'
t.schedule.entity = '企业实体'

//订单列表
t.orderList = {}
t.orderList.all = '全部'
t.orderList.to_confirm_quote = '待确认报价'
t.orderList.to_confirm_final = '待确认终稿'
t.orderList.final_modify = '终稿修改中'
t.orderList.to_quote = '等待报价'
t.orderList.in_production = '生产中'
t.orderList.order_status = '订单状态'
t.orderList.order_name = '订单名称'
t.orderList.order_duedate = '订单交期'
t.orderList.order_createdate = '下单时间'
t.orderList.order_export_quotation = '导出报价单'
t.orderList.order_export_feedbacks = '导出反馈内容'
t.orderList.only_show_feedbacks_order = '仅展示有反馈修改的订单'
t.orderList.order_num = '订单号'
t.orderList.order_name = '订单名称'
t.orderList.delivery_manager = '交付经理'
t.orderList.order_manager = '下单经理'
t.orderList.select_order_manager = '请选择下单经理'
t.orderList.order_action = '操作'
t.orderList.order_view_details = '查看详情'
t.orderList.select_order_status = '请选择订单状态'
t.orderList.input_order_nn = '请输入订单名称或订单号'
t.orderList.sort_createdate_des = '按下单时间由新到旧排序'
t.orderList.sort_createdate_asc = '按下单时间由旧到新排序'
t.orderList.sort_duedate_des = '按订单交期由新到旧排序'
t.orderList.sort_duedate_asc = '按订单交期由旧到新排序'
t.orderList.del_info = '是否确认删除（订单名称：{name}）?'
t.orderList.copy_info = '即将使用订单（订单名称：{name}）作为模板下单，是否确认?'
t.orderList.noselected = '还未选择任何订单'
t.orderList.noexport_orderstatus = '当前选择的订单状态不能导出'
t.orderList.noexport_orderstatus2 = '当前选择的下单方还未确认结算，暂不支持导出结算单'
t.orderList.noconfirm_orderstatus = '当前选择的订单状态不能确认'
t.orderList.batch = '批量报价单'
t.orderList.feedbacks = '反馈内容'
t.orderList.export_zip = '导出成zip格式'
t.orderList.export_single = '导出成单张'
t.orderList.select_provider = '请选择供应商实体'

//员工管理
t.employee = {}
t.employee.title = '员工管理'
t.employee.filter_employee_code = '工号'
t.employee.filter_employee_name = '姓名'
t.employee.filter_role = '角色'
t.employee.filter_status = '状态'
t.employee.filter_time = '更新时间'
t.employee.table_name = '姓名'
t.employee.table_num = '工号'
t.employee.input_employee_name = '请输入姓名'
t.employee.input_employee_code = '请输入工号'
t.employee.set_quit = '设置离职'
t.employee.set_on = '设置在职'
t.employee.quit = '离职'
t.employee.on = '在职'
t.employee.edit_role = '修改员工角色'
t.employee.spm = '高级交付经理'
t.employee.pm = '交付经理'
t.employee.select_role = '请选择角色'
t.employee.select_status = '请选择状态'

//结算
t.payment = {}
t.payment.all = '全部'
t.payment.confirm_by_customer = '待下单方确认结算'
t.payment.rejected_by_provider = '供应商驳回'
t.payment.status = '结算状态'
t.payment.order = '订单'
t.payment.update_time = '结算更新时间'
t.payment.present_date = '下单日期'
t.payment.confirm = '确认结算单'
t.payment.export = '导出结算单'
t.payment.order_num = '订单号'
t.payment.order_name = '订单名称'
t.payment.product_line_name = '产品线'
t.payment.order_offer = '订单报价'
t.payment.amount = '结算金额'
t.payment.confirm_by_provider = '待供应商确认结算'
t.payment.confirmed_by_provider = '供应商已确认'
t.payment.entity = '供应商实体'
t.payment.input_order_nn = '请输入订单名称或订单号'
t.payment.input_entity = '请输入企业实体'
t.payment.view = '查看结算单'
t.payment.stop = '终止'
t.payment.reject_by_provider = '供应商驳回'
t.payment.order_manager = '下单经理'
t.payment.invoice = '已开票'
t.payment.drawer_payment = '结算单'
t.payment.drawer_payment_detail = '结算单详情'
t.payment.drawer_invoice_no = '发票号'
t.payment.drawer_invoice_code = '发票代码'
t.payment.drawer_customer_entity = '下单方实体'
t.payment.drawer_provider_entity = '供应商实体'
t.payment.drawer_address = '地址'
t.payment.drawer_contact = '联系人'
t.payment.drawer_date = '结算更新日期'
t.payment.drawer_confirm_quote_date = '确认报价时间'
t.payment.drawer_confirm_final_draft_date = '确认验收时间'
t.payment.drawer_status = '状态'
t.payment.drawer_total_quotation = '报价总额'
t.payment.drawer_total_payment = '结算总额'
t.payment.drawer_quotation_approve = '批准结算'
t.payment.drawer_quotation_refuse = '拒绝结算'
t.payment.drawer_quotation_detail = '报价明细'
t.payment.drawer_service_type = '服务类型'
t.payment.drawer_source = '源语言'
t.payment.drawer_target = '目标语言'
t.payment.drawer_price = '单价'
t.payment.drawer_amount = '数量'
t.payment.drawer_quotation_count = '报价统计'
t.payment.drawer_quotation_value = '结算金额'
t.payment.invoice_info = '填写开票信息'
t.payment.input_invoice_code = '请输入发票代码'
t.payment.invoice_num = '发票号码'
t.payment.input_invoice_num = '请输入发票号码'
t.payment.refuse_success = '拒绝成功'
t.payment.refuse_info = '您已成功拒绝（订单名称：{name}）结算单！'
t.payment.quotation_success = '结算成功'
t.payment.quotation_info = '您已成功确认（订单名称：{name}）结算单！'
t.payment.quotation_batchinfo = '您已成功确认以下订单的结算单'
t.payment.quote_amount = '报价金额'
t.payment.nofilled_quote_price_tip = '请填写结算金额'
t.payment.selected_different_manage_tip = '请选择下单经理和交付经理一致的结算单'
t.payment.selected_statement = '请选择结算单'
t.payment.batch = '批量结算单'
t.payment.sure_by_provider = '待供应商确认'
t.payment.to_be_confirmed = '待确认'
t.payment.wait_provider_confirm = '请勾选状态为”待供应商确认结算“的订单'
t.payment.wait_placeOrder_confirm = '请勾选状态为”待下单方确认结算“的订单'
t.payment.no_invoice_code = '发票代码未填写'
t.payment.no_confirm_again = '您不能确认所选择的结算单'
t.payment.had_confirm_status = '已选择状态的结算单暂不支持导出'
t.payment.exportpdf = '导出PDF'
t.payment.part_no_invoice_power = '您所选的部分结算单暂无开票权限，请确认后重新选择'
t.payment.no_invoice_power = '您暂无所选结算单的开票权限，请确认后重新选择'
t.payment.no_write_all_settle = '请填完结算总额'
t.payment.positive_number = '请填写正数'
t.payment.customer_contacts = '下单方联系人'
t.payment.provider_contacts = '供应商联系人'
t.payment.different_cusAgencyId = '供应商实体不一致，不可批量确认！'

t.payment.upload_order = '上传报价单'
t.payment.donwload_order = '下载报价单'
t.payment.actual_order_price = '实际订单金额'
t.payment.actual_price = '实际金额'
t.payment.modify_price = '修改金额'
t.payment.quotation_explain = '报价说明'

// 翻译记录
t.trans_record = {}
t.trans_record.name = '翻译记录'
t.trans_record.source_text = '原文'
t.trans_record.source = '来源'
t.trans_record.trans_text = '译文'
t.trans_record.file_exit = '文件名已存在！请确认或修改文件名后再上传。'

// 客户配置
t.customerConfig = {}
t.customerConfig.base_info = '基本信息'
t.customerConfig.manager = '交付经理'
t.customerConfig.product_line_config = '产品线生产配置'
t.customerConfig.co_tax = '企业税号'
t.customerConfig.supplier_tax = '供应商税号'
t.customerConfig.supplier_admin = '供应商管理员'
t.customerConfig.company_name = '企业实体名'
t.customerConfig.supplier_name = '供应商实体名'
t.customerConfig.company_address = '企业地址'
t.customerConfig.supplier_address = '供应商地址'
t.customerConfig.create_time = '创建时间'
t.customerConfig.industry_and_field = '行业&领域'
t.customerConfig.new_program_client_project = '默认新建的Program绑定的Client Project'
t.customerConfig.program = 'program'
t.customerConfig.attention_0 = '注：'
t.customerConfig.attention_1 = '1.Source和Content Type相同的Program不能同时被绑定在同一个产品线中'
t.customerConfig.attention_2 =
  '2.如客户下单时选择的Source和Content Type与已有的Program不配，生产系统将默认新建一个Program并与该产品线绑定'
t.customerConfig.attention_3 = '3.新建的Program将以邮件的形式告知该产品线的交付经理'
t.customerConfig.moreinfo = '补充说明'
t.customerConfig.confirm = '确认'
t.customerConfig.supplier_baseInfo = '供应商基本信息'
t.customerConfig.add_new_supplier = '添加新供应商'
t.customerConfig.contact_productLine = '推荐产品线'
t.customerConfig.contact_lang_pair = '推荐语言对'
t.customerConfig.dock_jingyi_internal = '对接鲸译（内部版）'
t.customerConfig.no_dock_produce_sys = '不对接生产系统'
t.customerConfig.tip_select_recommend = '下单时选择该产品线时，该供应商将被推荐'
t.customerConfig.tip_select_recommend2 = '下单时选择该语言对时，该供应商将被推荐'
t.customerConfig.tip_select_recommend3 = '为避免供应商已产生工作量，如供应商选择有误建议终止订单重新下单'
t.customerConfig.discount_preset = '折扣预设'
t.customerConfig.send_ok_email = '发送确认邮件'
t.customerConfig.sure_del = '确定要删除吗？'
t.customerConfig.status_0 = '未确认'
t.customerConfig.status_1 = '已确认'
t.customerConfig.disabled = '已禁用'
t.customerConfig.confirm_disabled = '您确定要禁用吗？'
t.customerConfig.confirm_enable = '您确定要启用吗？'
t.customerConfig.disable = '禁用'
t.customerConfig.enable = '启用'
t.customerConfig.info_has_confirm = '信息已确认'
t.customerConfig.btn_new_manager = '新增交付经理'
t.customerConfig.new_manager_title = '新增交付经理'
t.customerConfig.edit_manager_title = '编辑交付经理'
// t.customerConfig.importSuccess = `导入成功，有效数据{efficientCount}条，无效数据{invalidCount}条`
t.customerConfig.importSuccess = `成功导入{efficientCount}条 ，{invalidCount}条格式错误或必填项为空无法导入`
t.customerConfig.has_order_confirm_diable = `还有订单尚未结算，是否确认禁用？`
t.customerConfig.send_email_succ = `邮件发送成功`
t.customerConfig.send_email_fail = `邮件发送失败`
t.customerConfig.add_succ = `添加成功`
t.customerConfig.edit_succ = `修改成功`
t.customerConfig.del_succ = `删除成功`
t.customerConfig.import_succ = `导入成功`
t.customerConfig.assignPM = '分配交付经理'
t.customerConfig.updatePM = '更换交付经理'
t.customerConfig.assignPMSuccess = '分配成功'
t.customerConfig.updatePMSuccess = '更换成功'
t.customerConfig.info_empty = '基本信息不能为空'
t.customerConfig.productLineName_placeholder = '请输入产品线名'
t.customerConfig.productLineIndustry_placeholder = '请输入行业领域'
t.customerConfig.delete_confirm_title = '是否确认删除'
t.customerConfig.staff_sure = '员工确认'
t.customerConfig.tax_has = '该客户下供应商税号已存在！'
t.customerConfig.entity_has = '该客户下供应商实体名已存在！'
t.customerConfig.sure_staff_tip = '请确认员工为<{name}>员工'
t.customerConfig.email_exists = '该员工的邮箱已存在！'

//验证
t.taxIdNumber = '税号'

t.orderInfo.submitFinalDraftSuccess = '上传终稿成功'
t.orderInfo.transRecordSuccess = '翻译记录上传成功'

// RockWell

t.RockWell = {}
t.RockWell.text1 = '确认折扣率'
t.RockWell.text2 = '确认数字'

export default t
